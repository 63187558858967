import styled, { css } from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as YesNoIcon } from 'assets/boolean.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/buttonIcons/checkmark.svg';
import { ReactComponent as ShareIcon } from 'assets/buttonIcons/share.svg';
import { ReactComponent as CheckboxIcon } from 'assets/checkbox.svg';
import { ReactComponent as CommentIcon } from 'assets/comment-icon.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as DateIcon } from 'assets/date.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as DropdownIcon } from 'assets/dropdown.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as CloudDownloadIcon } from 'assets/icons/download-cloud.svg';
import {
  ReactComponent as EllipsisIcon,
  ReactComponent as ToggleIcon,
} from 'assets/icons/ellipsis-icon.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/newtab-icon.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-cloud.svg';
import { ReactComponent as MediaIcon } from 'assets/media.svg';
import { ReactComponent as NotApplicableIcon } from 'assets/notapplicable.svg';
import { ReactComponent as RadioIcon } from 'assets/radio.svg';
import { ReactComponent as RefreshIcon } from 'assets/refresh-icon.svg';
import { ReactComponent as RenameIcon } from 'assets/rename.svg';
import { ReactComponent as XIcon } from 'assets/round-x-icon.svg';
import { ReactComponent as SaveIcon } from 'assets/save.svg';
import { ReactComponent as TextIcon } from 'assets/text.svg';
import { ReactComponent as TimeIcon } from 'assets/time.svg';

import { IconProps } from './props';

const boxShadow = `0 0 1px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.13)`;
const hoverBoxShadow = `0px 0px 1px rgba(41, 80, 255, 1), 0px 0px 0px 1px rgba(41, 80, 255, 1)`;
const ButtonRegular = css<IconProps>`
  background-color: ${({ color, disabled, border }) =>
    disabled && border !== 'none' ? colors.lightGray : color ?? colors.white};
  min-width: ${({ width }) => (width ? width : '7rem')};
  max-width: ${({ width }) => (width ? width : '14rem')};
  max-height: ${({ height }) => (height ? height : '3.6rem')};
  min-height: ${({ height }) => (height ? height : '3.6rem')};
  color: ${({ color }) => (color !== undefined ? colors.white : colors.black)};
  padding: ${({ padding }) => (padding ? padding : '0 1rem')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  box-shadow: ${({ border }) => (border !== 'none' ? boxShadow : '')};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${({ color }) => (color !== undefined ? colors.white : colors.mainBlue)};
    box-shadow: ${({ border }) => (border !== 'none' ? hoverBoxShadow : '')};
  }
`;

const ButtonContainer = styled.button<IconProps>`
  ${ButtonRegular}
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: 0.7rem;
  font-size: ${fontSizes.mediumSmall};
  line-height: 1.5715rem;
  margin: ${({ margin }) => (margin ? margin : `1.3px 5px`)};
  font-weight: 500;
  letter-spacing: 0.02rem;
`;

const Icon = css<IconProps>`
  margin: 0 1rem 0 0;
  width: ${({ iconsize }) => (iconsize ? iconsize : '1.5rem')};
  height: ${({ iconsize }) => (iconsize ? iconsize : '1.5rem')};
  vertical-align: middle;
  display: inline-block;
  & path {
    fill: ${({ color }) => (color !== undefined ? colors.white : colors.black)};
  }
  ${ButtonContainer}: hover & {
    fill: ${({ color }) => (color !== undefined ? colors.white : colors.mainBlue)};
  }
`;

const StyledSaveIcon = styled(SaveIcon)`
  ${Icon}
`;
const StyledDeleteIcon = styled(DeleteIcon)`
  ${Icon}
`;
const StyledCopyIcon = styled(CopyIcon)`
  ${Icon}
`;
const StyledRadioIcon = styled(RadioIcon)`
  ${Icon}
`;
const StyledEditIcon = styled(EditIcon)`
  ${Icon}
`;
const StyledTextIcon = styled(TextIcon)`
  ${Icon}
`;
const StyledMediaIcon = styled(MediaIcon)`
  ${Icon}
`;
const StyledDateIcon = styled(DateIcon)`
  ${Icon}
`;
const StyledYesNoIcon = styled(YesNoIcon)`
  ${Icon}
`;
const StyledNotApplicableIcon = styled(NotApplicableIcon)`
  ${Icon}
`;
const StyledCheckboxIcon = styled(CheckboxIcon)`
  ${Icon}
`;
const StyledDropdownIcon = styled(DropdownIcon)`
  ${Icon}
`;
const StyledTimeIcon = styled(TimeIcon)`
  ${Icon}
`;
const StyledToggleIcon = styled(ToggleIcon)`
  ${Icon}
`;
const StyledAddIcon = styled(AddIcon)`
  ${Icon}
`;
const StyledRenameIcon = styled(RenameIcon)`
  ${Icon}
`;
const StyledUploadIcon = styled(UploadIcon)`
  ${Icon}
`;
const StyledCloudDownloadIcon = styled(CloudDownloadIcon)`
  ${Icon}
`;

const StyledAcceptDesignIcon = styled(CheckmarkIcon)`
  ${Icon}
`;

const StyledAddCommentIcon = styled(CommentIcon)`
  ${Icon}
`;

const StyledReviewDesignIcon = styled(ShareIcon)`
  ${Icon}
`;

const StyledNewTabIcon = styled(NewTabIcon)`
  ${Icon}
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  ${Icon}
`;

const StyledExpandIcon = styled(ChevronRight)`
  ${Icon}
`;

const StyledCollapseIcon = styled(ChevronDown)`
  ${Icon}
`;

const StyledXIcon = styled(XIcon)`
  ${Icon}
`;

const StyledEllipsisIcon = styled(EllipsisIcon)`
  ${Icon}
  margin: 0;
`;

export {
  ButtonContainer,
  StyledAcceptDesignIcon,
  StyledAddCommentIcon,
  StyledAddIcon,
  StyledCheckboxIcon,
  StyledCloudDownloadIcon,
  StyledCollapseIcon,
  StyledCopyIcon,
  StyledDateIcon,
  StyledDeleteIcon,
  StyledDropdownIcon,
  StyledEditIcon,
  StyledEllipsisIcon,
  StyledExpandIcon,
  StyledMediaIcon,
  StyledNewTabIcon,
  StyledNotApplicableIcon,
  StyledRadioIcon,
  StyledRefreshIcon,
  StyledRenameIcon,
  StyledReviewDesignIcon,
  StyledSaveIcon,
  StyledTextIcon,
  StyledTimeIcon,
  StyledToggleIcon,
  StyledUploadIcon,
  StyledXIcon,
  StyledYesNoIcon,
};
