import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import borderRadius from 'helpers/constants/borderRadius';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { projectCategoriesRoute } from 'helpers/constants/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledCategoryIcon = styled(CategoryIcon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.8rem;

  path {
    fill: ${colors.darkGray};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  font-size: ${fontSizes.mediumSmall};
  font-weight: ${fontWeights.semiBold};
  height: 3.4rem;
  padding: 0.9rem 1.5rem;
  border: 0.1rem solid ${colors.neutralGray};
  border-radius: ${borderRadius.borderRadiusXs};
  color: ${colors.darkGray};
  width: fit-content;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const Separator = styled.div`
  margin: 0 0.5rem;
`;

export const Breadcrumbs = ({ category, projectId }: { category: string; projectId: string }) => {
  const { t } = useTranslation();
  return (
    <Wrapper data-testid="breadcrumbs">
      <StyledCategoryIcon data-testid="breadcrumb-icon" />
      <StyledLink to={projectCategoriesRoute(projectId)} data-testid="albums-link">
        {t('AlbumView.albums')}
      </StyledLink>
      <Separator>/</Separator>
      <span data-testid="album-category">{category}</span>
    </Wrapper>
  );
};
