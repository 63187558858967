import { Thumbnail as StandardThumbnail } from '@cpm/scanifly-shared-components';
import React, { useCallback, useState } from 'react';
import { MediaItem, SurveyMedia } from 'types';
import { KebabMenu } from '../KebabMenu/KebabMenu';
import { KebabMenuContent } from '../KebabMenuContent/KebabMenuContent';

export const Thumbnail = ({
  image,
  selectedImages,
  handleClick,
  setOpenMiniMenuId,
  openMiniMenuId,
}: {
  image: SurveyMedia | MediaItem;
  selectedImages: Record<string, SurveyMedia | MediaItem>;
  handleClick: (
    image: SurveyMedia | MediaItem,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  setOpenMiniMenuId: React.Dispatch<React.SetStateAction<string | null>>;
  openMiniMenuId: string | null;
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const onClick = useCallback(
    (image: any, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleClick(image, e);
      setIsActive((oldState) => !oldState);
    },
    [handleClick]
  );

  return (
    <StandardThumbnail
      kebabMenu={
        <KebabMenu
          content={
            <KebabMenuContent isActive={isActive} selectedImages={selectedImages} image={image} />
          }
          isActive={isActive}
          setOpenMiniMenuId={setOpenMiniMenuId}
          openMiniMenuId={openMiniMenuId}
          imageId={image.id}
        />
      }
      image={image}
      handleClick={onClick}
      withTooltip={true}
    />
  );
};
