import { DragDrop, useUppy } from '@uppy/react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import {
  FEATURE_LIST,
  getFeatureAvailability,
  MediaCategoryName,
  OVERAGE,
  PROJECT_TYPES,
  PROJECT_STATUS as SHARED_PROJECT_STATUS,
  UPLOAD_MANIFEST_INTENT,
} from '@cpm/scanifly-shared-data';
import { uuid4 } from '@sentry/utils';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Tus from '@uppy/tus';
import { Button, Checkbox, Spin, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import cn from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { MAP_DEFAULTS, MAP_ZOOM } from 'screens/MapWrapper/constants';

import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { boundariesRequested, resetBoundaries } from 'state/slices/boundariesSlice';
import { companySubscriptionInfoRequested, userCompanyRequested } from 'state/slices/companySlice';
import { resetServiceRequest } from 'state/slices/designServices/designServiceRequestsSlice';
import { selectAlbumView, setAlbumView } from 'state/slices/localPreferencesSlice';
import {
  projectMediasDeleted,
  projectMediasRequested,
  resetIsMediasDeletionSuccessful,
  resetProjectMedias,
} from 'state/slices/mediasSlice';
import {
  projectRequested,
  resetProject,
  updateProjectStatusById,
  userSuccessfullyUploadedProject,
  userUploadedProject,
} from 'state/slices/projectSlice';
import { saveUploadManifestRequested } from 'state/slices/uploadManifestsSlice';

import { fetchProjectMedias } from 'api/medias/mediasService';

import {
  Button as ActuallyUseableButtonNotPassiveAggressiveAtAll,
  GoBackButton,
  ModalContext,
  ZipButton,
} from 'components';
import { MediaCarouselContext, MediaCarouselProvider } from 'components/MediaCarousel';

import { ReactComponent as DownloadCloud } from 'assets/icons/download-cloud.svg';
import { ACCESS } from 'helpers/constants/access';
import {
  ALL_PHOTOS_NAME,
  ALL_PHOTOS_URL_ENCODED,
  ARCHIVE_NAME,
  DRONE_IMAGES,
} from 'helpers/constants/categories';
import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { MODES } from 'helpers/constants/projectModes';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftNotesRoute,
  draftProjectCategoryRoute,
  projectNotesRoute,
  rootRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDraftNotesRoute,
  scaniflyAdminDraftProjectCategoryRoute,
  scaniflyAdminProjectNotesRoute,
} from 'helpers/constants/routes';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import { usePreviewSelect } from 'helpers/hooks/usePreviewSelect';
import useToggle from 'helpers/hooks/useToggle';
import { environment, validators } from 'helpers/utils';
import { formatFileName } from 'helpers/utils/formatFileName';
import { mapAreaToProjectType } from 'helpers/utils/mapAreaToProjectType';
import { squareMetersToFeetConverter } from 'helpers/utils/metricConversions';
import { pluralize } from 'helpers/utils/pluralize';
import { ImageLocationPlugin, PhotoMetaPlugin, XHRUploadWithRetryPlugin } from 'helpers/utils/uppy';

import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';

import { openErrorNotification } from 'components/ZipButton/helpers';
import { DownloadButtonWrapper } from 'components/ZipButton/ZipButton';
import { openNotification } from 'helpers/utils/openNotification';
import { getProjectMediaCategories } from 'state/slices/projectMediaCategories';
import { ToggleLegacyViewButton } from '../AlbumView/ToggleLegacyViewButton/ToggleLegacyViewButton';
import { formatNameFromPath } from '../helpers';
import { AutomatedServiceNotificationBox } from './AutomatedServiceNotificationBox/AutomatedServiceNotificationBox';
import { NOTIFICATION_TYPES, SUPPORTED_FILE_TYPES, UPLOADING } from './constants';
import { DistantPhotoReview } from './DistantPhotoReview';
import DroneDataScore from './DroneDataScore/DroneDataScore';
import {
  dedupedFiles,
  getGeolocationFromMeta,
  getSuccessfulUploadRedirectionRoute,
  handleConfirmMediaDeletion,
  handleOpenCancelConfirm,
  isValidGeolocation,
  openFileListNotification,
  openNetworkNotification,
  openRestrictionFailedNotification,
  openUploadSuccessNotification,
  selectDefaultBoundingBoxType,
} from './helpers';
import NotificationBox from './NotificationBox/NotificationBox';
import { StyledDownloadLink } from './ProjectCategory';
import './ProjectCategory.scss';
import ProjectCategoryMap from './ProjectCategoryMap';
import { ZOOM_BY_PROJECT } from './ProjectCategoryMap/constants';
import {
  createBoundingBoxFeature,
  getBoundingBox,
  getBoundsForMarkers,
  getCoordsForBoundingBox,
  mapFeatureToBoundingBox,
} from './ProjectCategoryMap/helpers';
import ProjectTypeFilter from './ProjectTypeFilter/ProjectTypeFilter';
import RetryAlert from './RetryAlert/RetryAlert';
import { Subtitle } from './styledComponents';
import ThumbnailList from './ThumbnailList';
import UploadOverlay from './UploadOverlay/UploadOverlay';
import useUploadProjectValidations from './useUploadProjectValidations';
import ViewSwitchToggle from './ViewSwitchToggle/ViewSwitchToggle';

function ProjectCategory({ isAdminRoute = false }) {
  const selectedAlbumView = useSelector(selectAlbumView);
  const [addedFiles, setAddedFiles] = useState([]);
  const [checkedThumbnails, setCheckedThumbnails] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [popup, setPopup] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [boundaries, setBoundaries] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [features, setFeatures] = useState(null);
  const [droneImagesMapViewport, setDroneImagesMapViewport] = useState(MAP_DEFAULTS);
  const [failedFiles, setFailedFiles] = useState([]);
  const [isImageUploadFailed, setIsImageUploadFailed] = useState(false);
  const [showFileProgress, toggleShowFileProgress] = useToggle(false);
  const [isCheckedAll, toggleIsCheckedAll] = useToggle(false);
  const [mustSetDefaultProjectType, setMustSetDefaultProjectType] = useState(false);
  const [imagePreviews, setImagePreviews] = useState({});
  const [areaInSquareFeet, setAreaInSquareFeet] = useState(0);
  const [areaInSquareMeter, setAreaInSquareMeter] = useState(0);
  const [removedDistantPhotos, setRemovedDistantPhotos] = useState(false);
  const [droneImages, setDroneImages] = useState(undefined);

  const { mediaCarouselRef, setActiveIndex } = useContext(MediaCarouselContext);

  const { t } = useTranslation();

  const { projectId, categoryName } = useParams();
  const { hasUploadPermissions, isScaniflyAdmin, isDesignServiceProvider, isQa } = usePermissions();
  const { currentUser } = useSelector((state) => state.users);
  const { boundaries: savedBoundaries } = useSelector((state) => state.boundaries);
  const { projectCategories } = useSelector((state) => state.projectCategories);
  const { project, isProjectUploadedSuccessfully, isProjectRequestedLoading } = useSelector(
    (state) => state.project
  );
  const { projectMedias, isProjectMediasLoading, isMediasDeletionSuccessful } = useSelector(
    (state) => state.medias
  );

  const { company, companySubscriptionInfo } = useSelector((state) => state.company);

  const { activeSubscription } = useSelector((state) => state.adminSubscriptions);

  const category = formatNameFromPath(categoryName);
  const companyId = company?.id;

  const categoryObject = useMemo(
    () => projectCategories.find((item) => item.categoryName === category),
    [category, projectCategories]
  );

  const categoryId = useMemo(() => categoryObject?.id, [categoryObject]);

  // Media can't be finished loading if the category is unknown or if the
  // category is known and the media count does not match
  const isMediaLoading = useMemo(
    () =>
      !categoryObject ||
      isProjectMediasLoading ||
      ((categoryObject.uploadedMediasCount ?? 0) > 0 && projectMedias.length === 0),
    [isProjectMediasLoading, categoryObject, projectMedias?.length]
  );

  /**
   * Files were previously sorted alphabetically which caused
   * inconsistent placement of the currently uploading media.
   *
   * EX: if the album name started with a character before `u` the uploading items
   * would be at the end. However if it was after `u` the uploading items would
   * display at the top of the order.
   *
   * Media is sorted on the backend prior to sending to the
   * front end so we shouldn't need to worry about sort order here.
   */
  const allMedia = useMemo(() => [...addedFiles, ...projectMedias], [addedFiles, projectMedias]);

  const { selectedImage, setSelectedImage } = usePreviewSelect({
    mediaItems: allMedia,
    isMediaLoading,
  });

  const mountedRef = useRef();
  const categoryIdRef = useRef(categoryId);
  const boundariesRef = useRef(boundaries);
  const projectTypeRef = useRef(projectType);
  const projectMediasRef = useRef(projectMedias);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const setIsListView = useCallback(
    (listViewEnabled) => {
      dispatch(setAlbumView(listViewEnabled ? 'list' : 'thumbnail'));
    },
    [dispatch]
  );

  const currentAccessToken = localStorage.getItem('accessToken');
  const isProjectLoaded = project !== null;
  const isDraft = project?.statusDescription === PROJECT_STATUSES.DRAFT;
  const isRemoteDesignStarted = project?.status === SHARED_PROJECT_STATUS.remoteDesignStarted;
  const isNoFlight = project?.status === SHARED_PROJECT_STATUS.noFlight;
  const isDroneImagesUploaded = project?.status === SHARED_PROJECT_STATUS.droneImagesUploaded;
  const isDraftOrRemote = isDraft || isRemoteDesignStarted || isNoFlight;
  const isDroneImages = category === DRONE_IMAGES;
  const isDroneImagesDraft = isDraftOrRemote && isDroneImages;
  const isAllPhotos = category === MediaCategoryName.allPhotos;
  const shouldDisplayNoFlightButton = isDroneImagesDraft && !isNoFlight && !isDroneImagesUploaded;

  const droneDataScoreAvailabilityForPricingTier = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DRONE_DATA_SCORE,
    company?.pricingTier
  );
  const notesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.COMMENTS_MENTIONS,
    company?.pricingTier
  );
  const uppyLogToConsole = useFeatureToggle(ACCESS.UPPY_LOG_TO_CONSOLE);
  const uppyUseTus = useFeatureToggle(ACCESS.UPPY_USE_TUS);
  const designServicesAccess = getFeatureAvailability(
    false,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );
  const defaultProjectType = selectDefaultBoundingBoxType(activeSubscription);

  const { displayDeleteModal } = useContext(ModalContext);

  const updateProjectStatusToNoFlight = useCallback(async () => {
    return await dispatch(updateProjectStatusById(projectId, SHARED_PROJECT_STATUS.noFlight));
  }, [dispatch, projectId]);

  const indicateNoFlightOnClick = useCallback(() => {
    displayDeleteModal({
      title: t('NoFlightModal.title'),
      description: t('NoFlightModal.description'),
      actionButtonOnClick: updateProjectStatusToNoFlight,
      actionButtonLabel: t('buttonTexts.confirm'),
    });
  }, [updateProjectStatusToNoFlight, displayDeleteModal, t]);

  const uploadingAllowed = useMemo(
    () =>
      ((!isDroneImages && category && category !== ALL_PHOTOS_NAME && category !== ARCHIVE_NAME) ||
        isDroneImagesDraft) &&
      !isImageUploadFailed,
    [isDroneImages, category, isDroneImagesDraft, isImageUploadFailed]
  );

  // The difference between isNewProject & isDraft:
  // New Projects do not have a projectId yet because they haven't been created (no projectId in params, also no status)
  // Draft projects do have a projectId, but they don't have uploaded drone images.

  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? draftProjectCategoryRoute(projectId, category)
        : scaniflyAdminDraftProjectCategoryRoute(projectId, category),
    }),
  };

  const { isSaveAllowed, errorMessage } = useUploadProjectValidations({
    projectMedias: allMedia,
    projectType,
    boundaries,
  });

  const updateMapByMetadata = useCallback(
    (data) => {
      if (data?.find((item) => item.geolocation)) {
        return setDroneImagesMapViewport((prevState) => ({
          ...prevState,
          ...getBoundsForMarkers(data),
        }));
      } else if (isDroneImages && !isDraftOrRemote) {
        // TODO: Remove when we have added BE support for parsing geolocation for drone image markers
        return setDroneImagesMapViewport((prevState) => ({
          ...prevState,
          latitude: project?.geolocation.latitude,
          longitude: project?.geolocation.longitude,
          zoom: ZOOM_BY_PROJECT[project?.type] || MAP_ZOOM,
        }));
      }
    },
    [isDraftOrRemote, isDroneImages, project?.geolocation, project?.type]
  );

  const setData = useCallback(
    (files, updateMap = true) => {
      const data = [];
      for (const file of files) {
        if (
          !validators.isVideo(file) &&
          (isValidGeolocation(file.meta?.geolocation) || isValidGeolocation(file.geolocation))
        ) {
          data.push({
            id: file.id,
            isUploaded: !!file.projectId,
            data: file.data,
            fileName: !file.fileId && !isDroneImages ? UPLOADING : formatFileName(file),
            geolocation: file.meta?.geolocation
              ? getGeolocationFromMeta(file.meta.geolocation)
              : file.geolocation,
            thumbnailUrl: file.thumbnailUrl,
          });
        }
      }
      setMetadata(data);
      if (updateMap) {
        updateMapByMetadata(data);
      }
    },
    [isDroneImages, updateMapByMetadata]
  );

  const setFiles = (files) => {
    const { filteredFiles, duplicateFiles } = dedupedFiles(files, projectMediasRef.current);

    if (duplicateFiles.length && isDroneImages) {
      duplicateFiles.forEach((file) => uppy.removeFile(file.id));
      openFileListNotification({ files: duplicateFiles, type: NOTIFICATION_TYPES.DUPLICATE });
    }
    setAddedFiles(filteredFiles);
    setData([...projectMediasRef.current, ...filteredFiles]);
  };

  const updateOrCreateBoundaries = useCallback(
    (boundingBox, isResizing = false) => {
      const bounds = mapFeatureToBoundingBox(boundingBox, projectId);
      const { area } = bounds.boundingBox;
      if (boundaries && isResizing) {
        const newProjectType = mapAreaToProjectType(area);
        if (newProjectType !== projectType) {
          setProjectType(newProjectType);
        }
      }
      setAreaInSquareMeter(area);
      setAreaInSquareFeet(squareMetersToFeetConverter(area));
      setBoundaries(bounds);
    },
    [boundaries, projectId, projectType]
  );

  const updateBoundingBox = useCallback(
    (type) => {
      if (!type) {
        return;
      }
      const { latitude, longitude } = droneImagesMapViewport;
      const bounds = { latitude, longitude };
      const boundingBox = getBoundingBox(
        getCoordsForBoundingBox(bounds, { type, geolocation: project.geolocation })
      );
      setFeatures({
        type: 'FeatureCollection',
        features: [boundingBox],
      });
      updateOrCreateBoundaries(boundingBox);
      setDroneImagesMapViewport((prevState) => ({
        ...prevState,
        latitude: latitude ? latitude : project?.geolocation.latitude,
        longitude: longitude ? longitude : project?.geolocation.longitude,
        zoom: ZOOM_BY_PROJECT[type] || MAP_ZOOM,
      }));
    },
    [droneImagesMapViewport, project?.geolocation, updateOrCreateBoundaries]
  );

  const handleProjectTypeChange = useCallback(
    (event, defaultType) => {
      if (!event && projectType !== null) return;
      const type = event ? event.target.value : defaultType;
      setProjectType(type);
      updateBoundingBox(type);
    },
    [projectType, updateBoundingBox]
  );

  const uppy = useUppy(() => {
    const uppyOptions = {
      autoProceed: false,
      restrictions: {
        allowedFileTypes: isDroneImages ? SUPPORTED_FILE_TYPES.JPG : SUPPORTED_FILE_TYPES.ALL,
      },
      debug: !environment.isProductionBuild,
    };
    if (uppyLogToConsole) {
      uppyOptions.logger = {
        debug: (log) => console.log(log), //eslint-disable-line
        warn: (log) => console.log(log), //eslint-disable-line
        error: (log) => console.log(log), //eslint-disable-line
      };
    }
    const tempUppy = new Uppy(uppyOptions)
      .use(ImageLocationPlugin, {
        stateSetter: (files) => {
          setFiles(files);
          if (isDroneImages && files.length) {
            setMustSetDefaultProjectType(true);
          }
          if (!isDroneImages) {
            const intent = isDroneImages
              ? UPLOAD_MANIFEST_INTENT.drone
              : UPLOAD_MANIFEST_INTENT.survey;
            dispatch(
              saveUploadManifestRequested({
                projectId,
                mediaCategoryId: categoryIdRef.current,
                files: files,
                intent,
                uploadManifestId: uuid4(),
                // Upload-manifest uses userId when reinitializing manifest in memory (if it expired/evicted)
                // so must be userId used in S3 path to media
                userId: project?.submittedFor?.id ?? currentUser?.id,
              })
            );
            uppy.upload();
          }
        },
        toggleProgress: toggleShowFileProgress,
        rejectMissingGpsFiles: !!isDroneImages,
        renderFileErrors: (files) =>
          isDroneImages &&
          openFileListNotification({ files, type: NOTIFICATION_TYPES.MISSING_LOCATION }),
      })
      .use(PhotoMetaPlugin, { projectGeolocation: project?.geolocation })
      .use(ThumbnailGenerator)
      .on(
        'files-added',
        debounce((files) => {
          files.forEach(({ id, preview, size }) => {
            uppy.setFileMeta(id, { upload_size: size });
            if (preview) {
              setImagePreviews((prevState) => ({ ...prevState, [id]: preview }));
            }
          });
          toggleShowFileProgress();
        })
      )
      .on('restriction-failed', (_, error) => {
        openRestrictionFailedNotification(error, isDroneImages);
      })
      .on('is-offline', () => {
        openNetworkNotification(NOTIFICATION_TYPES.OFFLINE);
      })
      .on('back-online', () => {
        openNetworkNotification(NOTIFICATION_TYPES.ONLINE);
      })
      // TODO: Figure out how to do batch updates so we don't have to wait for all thumbnails to generate
      // .on('thumbnail:generated', (file, preview) => {
      //   setImagePreviews(prevState => ({ ...prevState, [file.id]: preview }));
      // })
      .on('thumbnail:all-generated', () => {
        const files = uppy.getFiles();
        setImagePreviews(files.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.preview }), {}));
      })
      .on('complete', (result) => {
        if (!result.failed.length) {
          if (isDroneImages) {
            setAddedFiles([]);
            dispatch(
              userUploadedProject({
                projectId,
                projectType: projectTypeRef.current,
                boundaries: boundariesRef.current,
                mode: MODES.NORMAL,
              })
            );
          } else {
            const currentUploads = uppy.getFiles().filter((file) => !file.progress.uploadComplete);
            setAddedFiles(currentUploads);
            if (!currentUploads.length) {
              uppy.cancelAll();
            }
          }
        } else {
          setFailedFiles(result.failed);
        }
        dispatch(
          projectMediasRequested({
            projectId: projectId,
            mediaCategoryName: category,
          })
        );
      });

    if (uppyUseTus) {
      tempUppy.use(Tus, {
        endpoint: isDroneImages
          ? `${process.env.REACT_APP_API}/medias/upload`
          : `${process.env.REACT_APP_API}/surveyMedias/upload`,
        headers: {
          Authorization: `Bearer ${currentAccessToken}`,
        },
        allowedMetaFields: null,
        retryDelays: [0, 1000, 3000, 5000],
        onShouldRetry(err, retryAttempt, _, next) {
          if (retryAttempt < 3) {
            return true;
          }

          return next(err);
        },
      });
    } else {
      tempUppy.use(XHRUploadWithRetryPlugin, {
        endpoint: isDroneImages
          ? `${process.env.REACT_APP_API}/medias/upload-xhr`
          : `${process.env.REACT_APP_API}/surveyMedias/upload-xhr`,
        headers: {
          Authorization: `Bearer ${currentAccessToken}`,
        },
        timeout: 0,
        allowedMetaFields: null, // send all metadata fields
      });
    }

    tempUppy.setMeta({
      project_id: projectId,
      /**
       * If project is an admin upload, upload photos under S3 bucket for the submittedFor user.
       * If not, upload photos under S3 bucket for the currently logged in user.
       */
      user_id: project?.submittedFor?.id ?? currentUser?.id,
      category_id: isDroneImages ? process.env.REACT_APP_CATEGORY_ID : categoryIdRef.current,
      category_name: category,
    });

    return tempUppy;
  });

  useEffect(() => {
    uppy.getPlugin('PhotoMetaPlugin')?.setOptions({ projectGeolocation: project?.geolocation });
  }, [project?.geolocation, uppy]);

  useEffect(() => {
    uppy.setMeta({
      project_id: projectId,
      /**
       * If project is an admin upload, upload photos under S3 bucket for the submittedFor user.
       * If not, upload photos under S3 bucket for the currently logged in user.
       */
      user_id: project?.submittedFor?.id ?? currentUser?.id,
      category_id: isDroneImages ? process.env.REACT_APP_CATEGORY_ID : categoryIdRef.current,
      category_name: category,
    });
  }, [
    uppy,
    projectId,
    project?.submittedFor?.id,
    currentUser?.id,
    isDroneImages,
    category,
    categoryIdRef,
  ]);

  useEffect(() => {
    dispatch(userCompanyRequested());
  }, [dispatch]);

  useEffect(() => {
    if (mustSetDefaultProjectType && isDroneImages) {
      handleProjectTypeChange(null, defaultProjectType);
      setMustSetDefaultProjectType(false);
    }
  }, [mustSetDefaultProjectType, defaultProjectType, handleProjectTypeChange, isDroneImages]);

  useEffect(() => {
    if (removedDistantPhotos) {
      updateBoundingBox(projectType);
      setRemovedDistantPhotos(false);
    }
  }, [projectType, removedDistantPhotos, updateBoundingBox]);

  useEffect(() => {
    if (companyId && !isScaniflyAdmin && !isDesignServiceProvider) {
      dispatch(companyActiveSubscriptionRequested(companyId));
    }
  }, [companyId, isScaniflyAdmin, dispatch, isDesignServiceProvider]);

  useEffect(() => {
    if (!isProjectMediasLoading && !isDroneImagesDraft) {
      setData(allMedia.filter((item) => item.geolocation));
    }
  }, [allMedia, isDroneImagesDraft, isProjectMediasLoading, setData]);

  useEffect(() => {
    if (failedFiles.length && projectMedias.length) {
      const { filteredFiles, duplicateFiles } = dedupedFiles(failedFiles, projectMedias);

      if (isDroneImages) {
        duplicateFiles.forEach((file) => uppy.removeFile(file.id));
      }

      if (filteredFiles.length) {
        setAddedFiles(filteredFiles);
        setIsImageUploadFailed(true);
        setData([...filteredFiles, ...projectMedias], false);
      }
    }
  }, [failedFiles, isDroneImages, projectMedias, setData, uppy]);

  useEffect(() => {
    boundariesRef.current = boundaries;
    categoryIdRef.current = categoryId;
    projectTypeRef.current = projectType;
    projectMediasRef.current = projectMedias;
  }, [boundaries, categoryId, projectType, projectMedias]);

  useEffect(() => {
    if (savedBoundaries && !isDraftOrRemote && isProjectLoaded) {
      setFeatures(createBoundingBoxFeature(savedBoundaries));
      setBoundaries(savedBoundaries);
    }
  }, [isDraftOrRemote, isProjectLoaded, savedBoundaries]);

  useEffect(() => {
    if (isProjectUploadedSuccessfully) {
      setUploadInProgress(false);
      dispatch(userSuccessfullyUploadedProject());
      dispatch(resetProject());
      openUploadSuccessNotification();
      history.push(
        getSuccessfulUploadRedirectionRoute(
          designServicesAccess,
          isAdminRoute,
          companySubscriptionInfo,
          projectId,
          projectType
        )
      );
    }
  }, [
    dispatch,
    history,
    isProjectUploadedSuccessfully,
    projectId,
    isAdminRoute,
    companySubscriptionInfo,
    projectType,
    designServicesAccess,
  ]);

  useEffect(() => {
    if (isMediasDeletionSuccessful) {
      setData([...projectMedias, ...addedFiles], false);
      dispatch(resetIsMediasDeletionSuccessful());
      dispatch(
        projectMediasRequested({
          projectId: projectId,
          mediaCategoryName: category,
        })
      );
    }
  }, [
    projectMedias,
    isDroneImages,
    metadata,
    projectId,
    category,
    isMediasDeletionSuccessful,
    addedFiles,
    setData,
    dispatch,
  ]);

  useEffect(() => {
    if (projectId) {
      dispatch(projectRequested(projectId));
      dispatch(getProjectMediaCategories(projectId));
      if (isDroneImages && !isDraftOrRemote) {
        dispatch(boundariesRequested(projectId));
      }
      dispatch(
        projectMediasRequested({
          projectId: projectId,
          mediaCategoryName: category,
        })
      );
    }
  }, [projectId, isNewProject, category, isDraftOrRemote, isDroneImages, dispatch]);

  useEffect(() => {
    if (isDroneImagesDraft) {
      if (!mountedRef.current) {
        mountedRef.current = true;
      } else {
        window.onbeforeunload = addedFiles.length ? () => true : undefined;
      }
      return () => {
        window.onbeforeunload = undefined;
        dispatch(resetProjectMedias());
        dispatch(resetBoundaries());
      };
    }
    if (company?.id && !isDesignServiceProvider) {
      dispatch(companySubscriptionInfoRequested(company?.id));
    }
    // We only want this to run once on mount and once on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let redirect;
    if (category !== DRONE_IMAGES) {
      if (
        projectCategories.length &&
        !projectCategories.find((item) => item.categoryName.includes(category))
      ) {
        // unknown project category redirect to All Photos, fallback to root route
        const allPhotos = projectCategories.find((item) => item.categoryName === ALL_PHOTOS_NAME);
        if (allPhotos) {
          const newPathname = location.pathname.replace(categoryName, ALL_PHOTOS_URL_ENCODED);
          const updatedUrl = `${newPathname}${location.search}${location.hash}`;
          history.replace(updatedUrl);
          history.push(updatedUrl);
        } else {
          redirect = true;
        }
      }
    }

    if (redirect) {
      history.push(rootRoute());
    }
  }, [
    category,
    history,
    isDraftOrRemote,
    isNewProject,
    project?.id,
    projectCategories,
    projectId,
    location,
    categoryName,
  ]);

  useEffect(() => {
    const getDroneImages = async () => {
      if (category === MediaCategoryName.allPhotos) {
        const apiResult = await fetchProjectMedias(projectId);
        setDroneImages(apiResult.data);
      }
    };
    getDroneImages();
  }, [category, projectId]);

  const handleCheckAll = () => {
    if (isCheckedAll) {
      setCheckedThumbnails([]);
    } else {
      setCheckedThumbnails(
        isDroneImages ? allMedia.map((file) => file.id) : projectMedias.map((file) => file.id)
      );
    }
    toggleIsCheckedAll();
  };

  const handleCheck = useCallback(
    (event, media) => {
      const checked = event.target.checked;
      if (isCheckedAll || (checked && checkedThumbnails.length === allMedia.length - 1)) {
        toggleIsCheckedAll();
      }
      setCheckedThumbnails(
        checked
          ? [...checkedThumbnails, media.id]
          : checkedThumbnails.filter((id) => id !== media.id)
      );
    },
    [checkedThumbnails, isCheckedAll, allMedia, toggleIsCheckedAll]
  );

  const handleDeleteThumbnails = () => {
    checkedThumbnails.map((id) => uppy.removeFile(id));
    const projectMediasToDelete = projectMedias.reduce((filtered, file) => {
      if (checkedThumbnails.includes(file.id)) {
        filtered.push(file.id);
      }
      return filtered;
    }, []);

    if (isCheckedAll) {
      toggleIsCheckedAll();
      setPopup(null);
      setSelectedImage(null);
      resetBoundariesData();
    } else {
      if (checkedThumbnails.includes(popup?.id)) {
        setPopup(null);
      }
      if (checkedThumbnails.includes(selectedImage?.id)) {
        setSelectedImage(null);
      }
      if (boundaries && checkedThumbnails.length === metadata.length) {
        resetBoundariesData();
      }
    }
    setAddedFiles(uppy.getFiles());
    setMetadata(metadata.filter((meta) => !checkedThumbnails.includes(meta.id)));
    setCheckedThumbnails([]);

    dispatch(
      projectMediasDeleted({
        medias: { medias: projectMediasToDelete },
        mediaCategoryName: category,
      })
    );
  };

  /**
   * Called by <DistantPhotoReview> after user confirms which photos
   * to remove from Uppy
   */
  const handleRemoveDistantPhotos = useCallback(
    (distantPhotosToRemove) => {
      const distantPhotosToRemoveIds = distantPhotosToRemove.map(({ id }) => {
        uppy.removeFile(id);
        return id;
      });

      if (distantPhotosToRemoveIds.length === metadata.length) {
        resetBoundariesData();
      }
      const remainingFiles = uppy.getFiles();
      setAddedFiles(remainingFiles);
      setData(remainingFiles, true);
      setRemovedDistantPhotos(true);
    },
    [metadata, setData, uppy]
  );

  const handleSelectImage = useCallback(
    ({ mediaItem, evt, index }) => {
      if (popup) {
        setPopup(null);
      }
      if (!mediaItem || selectedImage?.id === mediaItem?.id) {
        evt.currentTarget.blur();
        return setSelectedImage(null);
      }
      setActiveIndex(index);
      mediaCarouselRef?.current?.goTo(index);
      setSelectedImage(mediaItem);
    },
    [mediaCarouselRef, popup, selectedImage?.id, setSelectedImage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRenderMap = useCallback(
    (image, event) => {
      if (selectedImage) {
        setSelectedImage(null);
      }
      if (popup?.id === image.id) {
        event.currentTarget.blur();
        return setPopup(null);
      }
      setPopup(metadata.find((media) => media.id === image.id));
    },
    [metadata, popup?.id, selectedImage, setSelectedImage]
  );

  const getDraftNotesRoute = () => {
    return isAdminRoute ? scaniflyAdminDraftNotesRoute(projectId) : draftNotesRoute(projectId);
  };

  const getProjectNotesRoute = () => {
    return isAdminRoute ? scaniflyAdminProjectNotesRoute(projectId) : projectNotesRoute(projectId);
  };

  const handleNext = () => {
    history.push(isDraftOrRemote ? getDraftNotesRoute() : getProjectNotesRoute());
  };

  const handleExit = () => {
    history.push(isAdminRoute ? scaniflyAdminCustomerSupportUploadRoute() : rootRoute());
  };

  const shouldDisplayQuotaErrorMessage = useMemo(
    () =>
      !isScaniflyAdmin &&
      ((!activeSubscription?.largeProjectQuota && projectType === PROJECT_TYPES.LARGE) ||
        (!activeSubscription?.smallProjectQuota && projectType === PROJECT_TYPES.SMALL)),
    [
      isScaniflyAdmin,
      activeSubscription?.largeProjectQuota,
      activeSubscription?.smallProjectQuota,
      projectType,
    ]
  );

  const handleUpload = () => {
    if (shouldDisplayQuotaErrorMessage) {
      confirm({
        title: (
          <div className="ProjectCategory-TitleWrapper">
            <div>{t('buttonTexts.confirm')}</div>
            <Tooltip
              placement="topLeft"
              title={
                <section>
                  <div>Extra fees:</div>
                  <div>Small: ${activeSubscription?.smallOverage || OVERAGE.small} / project</div>
                  <div>Large: ${activeSubscription?.largeOverage || OVERAGE.large} / project</div>
                </section>
              }
            >
              <QuestionMarkIcon className="ProjectCategory-QuestionMarkIcon" />
            </Tooltip>
          </div>
        ),
        content: <div>{t('tooltipTexts.subscriptionDoesNotCover')}</div>,
        okText: t('buttonTexts.uploadProject'),
        okButtonProps: { style: { background: colors.blue } },
        onOk: () => {
          handleUploadStart();
          return false;
        },
        ...CONFIRM_PROPS,
      });
    } else {
      handleUploadStart();
    }
  };

  const handleUploadStart = () => {
    const mode = MODES.LUDICROUS;

    dispatch(resetServiceRequest());
    if (addedFiles.length) {
      setUploadInProgress(true);
      return uppy.upload();
    }
    if (projectMedias.length && isSaveAllowed) {
      dispatch(
        userUploadedProject({
          projectId,
          projectType,
          boundaries,
          mode,
        })
      );
    }
  };

  const handleCancelUpload = () => {
    if (uploadInProgress) {
      uppy.cancelAll();
      setUploadInProgress(false);
      resetBoundariesData();
    } else {
      uppy.cancelAll();
      setFailedFiles([]);
      setIsImageUploadFailed(false);
    }
    setAddedFiles([]);
    setMetadata([]);
    if (isCheckedAll) {
      toggleIsCheckedAll();
    }
    setCheckedThumbnails([]);
    dispatch(
      projectMediasRequested({
        projectId: projectId,
        mediaCategoryName: category,
      })
    );
  };

  const handleRetry = () => {
    setUploadInProgress(true);
    setIsImageUploadFailed(false);
    setFailedFiles([]);
    uppy.retryAll();
  };

  const resetBoundariesData = () => {
    setBoundaries(null);
    setFeatures(null);
    setDroneImagesMapViewport(MAP_DEFAULTS);
    setProjectType(null);
    setAreaInSquareFeet(0);
    setAreaInSquareMeter(0);
  };

  const handleSingleDownloadClick = () => {
    const checked = projectMedias.find((media) => media.id === checkedThumbnails[0]);
    if (checked?.imgUrl) {
      openNotification({
        type: 'success',
        title: 'Success!',
        text: `You have successfully downloaded ${checked.originalFilenameWithoutExtension}${checked.originalFileExtension}`,
      });
    } else {
      openErrorNotification();
    }
  };

  const automatedDesignServicesProduct = useMemo(() => {
    if (activeSubscription) {
      if (activeSubscription.automatedTrueUp) {
        return t('DesignServiceSpecifications.trueUp');
      } else if (
        activeSubscription.automatedWireframeLargeOnsite ||
        activeSubscription.automatedWireframeSmallOnsite
      ) {
        return t('DesignServiceSpecifications.wireframe');
      }
    }
  }, [activeSubscription, t]);

  return (
    <>
      <Prompt
        when={!!addedFiles.length}
        message={(location) => {
          // usePreviewSelect will modify query strings
          // only trigger prompt if changing url
          if (location.pathname.includes('albums/Drone-Images')) {
            return true;
          }
          return t('alertMessages.unsavedChanges');
        }}
      />
      {uploadInProgress && !isImageUploadFailed && (
        <UploadOverlay
          handleCancel={() =>
            handleOpenCancelConfirm(uploadInProgress, handleCancelUpload, projectMedias.length)
          }
          uppy={uppy}
        />
      )}
      <div className="ProjectCategory">
        <div className="ProjectCategory-Wrapper">
          {isAdminRoute && (
            <div className="ProjectInfo-SubmittedFor">
              {project?.submittedFor &&
                `Submitted for: ${project.submittedFor.firstName} ${project.submittedFor.lastName} at ${project.submittedFor.company.name}`}
            </div>
          )}
          {isQa ? <ToggleLegacyViewButton toLegacy={false} /> : null}
          <h2 className="ProjectDesigns-ProjectName">{project?.name}</h2>
          <div className="ProjectCategory-Category-Wrapper">
            {!isDroneImagesDraft && (
              <div className="ProjectCategory-Category">
                <CategoryIcon className="ProjectCategory-Category-Icon" />
                <span className="ProjectCategory-Category-Name">{category}</span>
              </div>
            )}
            <Subtitle>
              {isDroneImages && isDraftOrRemote ? t('ProjectCategories.upload') : null}
            </Subtitle>
            {shouldDisplayNoFlightButton && (
              <ActuallyUseableButtonNotPassiveAggressiveAtAll
                icon="notapplicable"
                label={t('buttonTexts.indicateNoFlight')}
                onClick={indicateNoFlightOnClick}
                color={'blue'}
                width={'20rem'}
              />
            )}
            {!isDroneImagesDraft &&
              (checkedThumbnails.length === 1 ? (
                <StyledDownloadLink
                  id="image-download"
                  href={projectMedias.find((media) => media.id === checkedThumbnails[0])?.imgUrl}
                  download
                  rel="noreferrer"
                  className="ProjectCategory-DownloadButton"
                  onClick={handleSingleDownloadClick}
                >
                  <DownloadButtonWrapper>
                    <DownloadCloud />
                    <p>{t('buttonTexts.downloadSelected')}</p>
                  </DownloadButtonWrapper>
                </StyledDownloadLink>
              ) : (
                <ZipButton
                  files={
                    checkedThumbnails.length > 0
                      ? projectMedias.filter((media) => checkedThumbnails.includes(media.id))
                      : projectMedias
                  }
                  folderName={`${project?.name} ${category}`}
                  secondaryFiles={isAllPhotos ? droneImages : undefined}
                  secondaryFolderName={`${project?.name} drone images`}
                  buttonText={
                    checkedThumbnails.length > 0
                      ? 'buttonTexts.downloadSelected'
                      : 'buttonTexts.download'
                  }
                />
              ))}
          </div>
          {automatedDesignServicesProduct ? (
            <AutomatedServiceNotificationBox product={automatedDesignServicesProduct} t={t} />
          ) : null}
          {uploadingAllowed &&
            (hasUploadPermissions ? (
              <DragDrop
                uppy={uppy}
                locale={{
                  strings: {
                    // Text to show on the droppable area.
                    // `%{browse}` is replaced with a link that opens the system file selection dialog.
                    dropHereOr: 'Drop files here or %{browse}',
                    // Used as the label for the link that opens the system file selection dialog.
                    browse: 'browse',
                  },
                }}
              />
            ) : (
              <div className={cn({ 'uppy-DragDrop--Disabled': !hasUploadPermissions })}>
                {t('tooltipTexts.uploadPermissionsDenied')}
              </div>
            ))}
          {isImageUploadFailed && (
            <RetryAlert
              error={errorMessage}
              addedFileCount={addedFiles.length}
              recoveredFileCount={projectMedias.length}
              handleCancelUpload={handleCancelUpload}
              handleRetry={handleRetry}
              isSaveAllowed={isSaveAllowed}
              uploadInProgress={uploadInProgress}
            />
          )}
          {isDroneImages && !isDraftOrRemote && !isProjectRequestedLoading ? (
            <DroneDataScore
              droneData={project?.droneData}
              projectType={project?.type}
              projectId={project?.id}
              completedDate={project?.completedDate}
              droneDataScoreAvailabilityForPricingTier={droneDataScoreAvailabilityForPricingTier}
            />
          ) : null}
          <Spin
            tip={
              (!isDroneImages || isDraftOrRemote) &&
              !isProjectMediasLoading &&
              'Parsing location data...'
            }
            spinning={showFileProgress || (isProjectMediasLoading && !!allMedia.length)}
            size="medium"
          >
            {!!(isDroneImagesDraft && (addedFiles.length || projectMedias.length)) && (
              <>
                <ProjectTypeFilter
                  handleChange={(event) => handleProjectTypeChange(event)}
                  projectType={boundaries && projectType}
                  areaInSquareFeet={areaInSquareFeet}
                  areaInSquareMeter={areaInSquareMeter}
                />
              </>
            )}
            <div
              className={cn('ProjectCategory-Actions-Wrapper', {
                'ProjectCategory-Actions-Wrapper--Hidden': !allMedia.length,
              })}
            >
              <div className="ProjectCategory-Actions-SelectAll-Wrapper">
                <Checkbox
                  onChange={handleCheckAll}
                  checked={isCheckedAll}
                  disabled={isImageUploadFailed || (!isDroneImages && !projectMedias.length)}
                  aria-disabled={isImageUploadFailed || (!isDroneImages && !projectMedias.length)}
                />
                <span className="ProjectCategory-Actions-SelectAll-Title">Select All</span>
                <span className="ProjectCategory-Actions-SelectAll-Subtitle">
                  • {checkedThumbnails.length} / {allMedia.length}
                </span>
              </div>
              <div className="ProjectCategory-Actions-Buttons">
                <ViewSwitchToggle
                  isListView={selectedAlbumView === 'list'}
                  setIsListView={setIsListView}
                />
                {(!isDroneImages || isDraftOrRemote) && (
                  <Button
                    disabled={!checkedThumbnails.length || isImageUploadFailed}
                    aria-disabled={!checkedThumbnails.length || isImageUploadFailed}
                    onClick={() =>
                      handleConfirmMediaDeletion(handleDeleteThumbnails, checkedThumbnails.length)
                    }
                    className="Button--Red ProjectCategory-Button--Square"
                    aria-label={`remove selected ${pluralize(checkedThumbnails, 'upload')}`}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </div>
            </div>
            <ThumbnailList
              imagePreviews={imagePreviews}
              selectedThumbnail={popup}
              thumbnails={allMedia}
              isDraft={isDraftOrRemote}
              isDroneImages={isDroneImages}
              isListView={selectedAlbumView === 'list'}
              handleSelectImage={handleSelectImage}
              handleCheck={handleCheck}
              handleRenderMap={handleRenderMap}
              checkedThumbnails={checkedThumbnails}
              selectedImage={selectedImage}
              droneDataScoreAccess={droneDataScoreAvailabilityForPricingTier}
              isDroneDataAvailable={Boolean(Object.keys(project?.droneData ?? {}).length)}
            />
            <div className="ProjectCategory-Buttons">
              <div className="ProjectCategory-Buttons-Wrapper">
                {
                  isDraftOrRemote || !isDroneImages ? <GoBackButton /> : <div /> //temporarily rendering a div to not mess up our flex justification
                }
                {projectType && (
                  <NotificationBox
                    isError={shouldDisplayQuotaErrorMessage}
                    selectedType={projectType}
                    smallOverage={activeSubscription?.smallOverage || OVERAGE.small}
                    largeOverage={activeSubscription?.largeOverage || OVERAGE.large}
                  />
                )}
                {isDroneImagesDraft ? (
                  <Tooltip title={!isSaveAllowed && errorMessage}>
                    <Button
                      onClick={handleUpload}
                      disabled={!isSaveAllowed || isImageUploadFailed}
                      aria-disabled={!isSaveAllowed || isImageUploadFailed}
                      className="Button--Blue"
                    >
                      {t('buttonTexts.uploadProject')}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={!isDraftOrRemote && notesAccess ? handleNext : handleExit}
                    className="Button--White"
                  >
                    {!isDraftOrRemote && notesAccess
                      ? t('buttonTexts.next')
                      : t('buttonTexts.saveAndExit')}
                  </Button>
                )}
                <DistantPhotoReview
                  imagePreviews={imagePreviews}
                  onRemoved={handleRemoveDistantPhotos}
                  projectMedias={allMedia}
                />
              </div>
            </div>
          </Spin>
        </div>
        <div className="ProjectCategory-Element">
          <ProjectCategoryMap
            allMedia={allMedia}
            imagePreviews={imagePreviews}
            metadata={metadata}
            pin={project?.geolocation}
            selectedImagePreview={selectedImage}
            popup={popup}
            selectedMarkers={checkedThumbnails}
            setPopup={setPopup}
            disableBoundingBox={!isDroneImagesDraft}
            setSelectedImagePreview={setSelectedImage}
            boundaries={isDroneImages && allMedia.length ? boundaries : null}
            droneImagesMapViewport={droneImagesMapViewport}
            features={features}
            setFeatures={setFeatures}
            updateOrCreateBoundaries={isDroneImagesDraft ? updateOrCreateBoundaries : null}
            projectType={isDroneImages ? projectType || project?.type || defaultProjectType : null}
          />
        </div>
      </div>
    </>
  );
}

function ProjectCategoryWithMediaCarouselProvider(props) {
  return (
    <MediaCarouselProvider>
      <ProjectCategory {...props} />
    </MediaCarouselProvider>
  );
}

export default ProjectCategoryWithMediaCarouselProvider;

ProjectCategory.propTypes = {
  isAdminRoute: PropTypes.bool,
};
