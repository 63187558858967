import React, { useState } from 'react';
import styled from 'styled-components';
import { MediaItem, SurveyMedia } from 'types';
import { Thumbnail } from '../Thumbnail/Thumbnail';

const ThumbnailGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0.5rem;
  margin: 1rem 0;
  padding-bottom: 10rem;
`;

export const ThumbnailGrid = ({ album }: { album: (SurveyMedia | MediaItem)[] }) => {
  const [selectedImages, setSelectedImages] = useState<Record<string, SurveyMedia | MediaItem>>({});
  const [openMiniMenuId, setOpenMiniMenuId] = useState<null | string>(null);

  const handleClick = (
    img: SurveyMedia | MediaItem,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const selectedImageInCache = selectedImages[img.id];
    if (selectedImageInCache) {
      setSelectedImages((prevState) => {
        const newState = { ...prevState };
        delete newState[img.id];
        return newState;
      });
    } else {
      setSelectedImages((prevState) => ({ ...prevState, [img.id]: img }));
    }
    e.stopPropagation();
  };

  return (
    <ThumbnailGridWrapper>
      {album.map((image) => (
        <Thumbnail
          key={image.id}
          image={image}
          selectedImages={selectedImages}
          handleClick={(image, e) => handleClick(image, e)}
          setOpenMiniMenuId={setOpenMiniMenuId}
          openMiniMenuId={openMiniMenuId}
        />
      ))}
    </ThumbnailGridWrapper>
  );
};
