import { DRONE_IMAGES } from 'helpers/constants/categories';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MediaItem, SurveyMedia } from 'types';

const StyledContentWrapper = styled.div`
  position: absolute;
  top: 3.25rem;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border: 0.1rem solid ${colors.neutralGray};
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  font-size: ${fontSizes.small};
  width: 12rem;
`;

const SelectionWrapper = styled.div`
  border-bottom: 0.1rem solid ${colors.neutralGray};
  margin: 0.25rem 0;
`;

const ActionWrapper = styled.button`
  margin: 0.25rem 0;
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  cursor: pointer;

  :disabled {
    color: ${colors.neutralGray};
  }
`;

export const KebabMenuContent = ({
  selectedImages,
  isActive,
  image,
}: {
  isActive: boolean;
  selectedImages: Record<string, SurveyMedia | MediaItem>;
  image: SurveyMedia | MediaItem;
}) => {
  const { t } = useTranslation();
  const selectedImageLength = Object.keys(selectedImages).length;
  const isDeleteDisabled = image.mediaCategory?.categoryName === DRONE_IMAGES;

  const actions = useMemo(
    () => [
      {
        title: t('AlbumView.view'),
        handleClick: () => {
          // TODO: implement image viewer functionality
          alert('This is going to open the image viewer in the future');
        },
        isDisabled: false,
      },
      {
        title: t('AlbumView.delete'),
        handleClick: () => {
          // TODO: implement delete functionality
          alert('This is going to delete the image in the future');
        },
        isDisabled: isDeleteDisabled,
      },
      {
        title: t('AlbumView.download'),
        handleClick: () => {
          // TODO: implement download functionality
          alert('This is going to download the image in the future');
        },
        isDisabled: false,
      },
    ],
    [isDeleteDisabled, t]
  );

  return (
    <StyledContentWrapper onClick={(e) => e.stopPropagation()}>
      {isActive ? (
        <SelectionWrapper>
          {t('AlbumView.selectedImageCount', { selectedImageLength })}
        </SelectionWrapper>
      ) : null}
      {actions.map((action) => (
        <ActionWrapper key={action.title} onClick={action.handleClick} disabled={action.isDisabled}>
          {action.title}
        </ActionWrapper>
      ))}
    </StyledContentWrapper>
  );
};
