import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div<{ $toLegacy?: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${colors.neutralGray};
  background: ${colors.white};
  margin-bottom: 1rem;
  font-size: ${fontSizes.small};
  width: ${({ $toLegacy }) => ($toLegacy ? 'fit-content' : 'auto')};
`;

const StyledLink = styled(Link)`
  display: inline-block;
  width: auto;
  border-radius: 1rem;
  padding: 0.75rem 1.5rem;
  background: ${colors.neutralBlue};
  color: ${colors.mainBlue};
  cursor: pointer;
  font-size: ${fontSizes.small};
  white-space: nowrap;
`;

const Description = styled.div`
  margin-left: 1rem;
`;

export const ToggleLegacyViewButton = ({ toLegacy }: { toLegacy?: boolean }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    params.set('legacy', toLegacy ? '1' : '0');
    return params;
  }, [location.search, toLegacy]);

  return (
    <Wrapper $toLegacy={toLegacy}>
      <StyledLink
        to={{
          ...location,
          search: searchParams.toString(),
        }}
        data-testid={toLegacy ? 'back-to-legacy-albums-button' : 'back-to-new-albums-button'}
      >
        {t('Generic.back')}
      </StyledLink>
      <Description>
        {t('AlbumView.takeMeBack', { type: toLegacy ? t('AlbumView.legacy') : t('AlbumView.new') })}
      </Description>
    </Wrapper>
  );
};
