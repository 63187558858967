import { ReactNode, useState } from 'react';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

type TooltipTextProps = {
  visible: boolean;
  bottom?: boolean;
  bottomPercent: number;
  leftPercent: number;
};

const TooltipText = styled.div<TooltipTextProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  width: 15rem;
  background-color: ${colors.black};
  font-size: 12px;
  color: ${colors.white};
  text-align: center;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  position: absolute;
  z-index: 1;
  ${(props) => (props.bottom ? 'top: 125%;' : `bottom: ${props.bottomPercent}%;`)}
  left: ${(props) => `${props.leftPercent}%`};
  margin-left: -50px;
  opacity: ${(props) => (props.visible ? 0.9 : 0)};
  transition: opacity 0.3s;

  /* Arrow */
  &:after {
    content: '';
    position: absolute;
    ${(props) => (props.bottom ? 'bottom: 100%' : 'top: 100%')};
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) =>
      props.bottom
        ? 'transparent transparent black transparent'
        : 'black transparent transparent transparent'};
  }
`;

type Props = {
  children: ReactNode;
  title: string;
  bottom?: boolean;
  /**
   * Adding these until this component can be refactored to work with with a set of predefined `placement` or `position` prop
   */
  bottomPercent?: number;
  leftPercent?: number;
};

const Tooltip = ({ children, title, bottom, bottomPercent = 125, leftPercent = -50 }: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <TooltipContainer
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      data-testid="tooltip"
    >
      {children}
      <TooltipText
        visible={visible}
        bottom={bottom}
        bottomPercent={bottomPercent}
        leftPercent={leftPercent}
      >
        {title}
      </TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
